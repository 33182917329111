<template>
  <div class="bg-cover bg-gray-100 font-roboto">
    <div class="max-w-[90%] min-h-screen mx-auto py-5">

      <TheForm/>

      
    </div>
  </div>
</template>

<script>

import TheForm from '../components/TheForm.vue'


export default {
  
  components: { TheForm },
  data(){
    return{
    }
  },
  methods:{

  },
  computed:{


  },
  async created(){
     

     
   }
}
</script>
