<template>
    <div class="flex flex-row">
        <input class="form-input mt-1 block w-full rounded-lg border-none" :placeholder="'Add Item'"
            v-model="item['value']" @submit.prevent>
        <button type="button" class="flex space-x-2 justify-start mt-3" @click="delField()">
            <div v-show=showDel class="mx-1">
                <!-- - -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="red" class="w-5 h-5">
                    <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z"
                        clip-rule="evenodd" />
                </svg>


            </div>
        </button>
    </div>


</template>
    
<script>
export default {

    name: 'BaseMultiValueTextInput',
    props: {
        id: Number,
        item: Object,
        showDel: Boolean
    },
    methods: {
        delField() {
            //emit event to parents
            //console.log("Delete: ", this.id)

            this.$emit("deleteItem", this.id)
        }

    },
    watch: {
        item: {
            handler(newValue, oldValue) {
                // console.log(newValue)
                this.$emit("oneValueChange", newValue)
                //need to set ad id value of 
            }, deep: true
        }
    }
}


</script>