<template>
	<router-link to="/"
		><img src="../assets/knb_logo_horizontal.png" alt="k-logo" :class="logoStyle"
	/></router-link>
	<!-- class="object-scale-down h-11 w-11 mx-auto justify-center" -->
</template>

<script>
export default {
	name: "BaseKLogo",
	props: {
		logoStyle: String,
	},
};
</script>
