<template>
	<section>
		<form @submit.prevent="onSubmit" autocomplete="off">
			<label class="relative block">
				<span class="absolute inset-y-0 left-0 flex items-center pl-3 pb-5">
					<svg
						id="Capa_1"
						class="text-gray-400 h-4 w-4 fill-current"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						version="1.1"
						x="0px"
						y="0px"
						viewBox="0 0 56.966 56.966"
						style="enable-background: new 0 0 56.966 56.966"
						xml:space="preserve"
						width="512px"
						height="512px"
					>
						<path
							d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
						/>
					</svg>
				</span>
				<input
					id="search-bar"
					v-model="searchQuery"
					class="mb-5 pl-10 focus:outline-none border-2 border-gray-300 bg-white h-10 min-w-full rounded-full text-sm font-roboto w-11/12"
					type="text"
					ref="toFocus"
					placeholder="Search"
				/>
			</label>
		</form>
	</section>
</template>

<script>
export default {
	name: "TheSearchBar",
	data() {
		return {
			searchQuery: "",
		};
	},
	methods: {
		async onSubmit() {
			if (this.searchQuery.length > 0) {
				this.$store.commit("setSearchQuery", this.searchQuery);

				// do the search here
				this.$store.commit("setSearchLoading", true);
				this.$router.push({
					name: "SearchView",
					query: {
						query: this.searchQuery,
					},
				});
			} else {
				this.$refs.toFocus.placeholder =
					"Your Search Text Is Empty. Please Type Your Search Text And Press Enter";
			}
		},
	},
};
</script>
