<template>
  <div class="email-button">
    <button
      class="group p-0 w-12 h-12 rounded-full bg-white border-[2px] border-[#0072C6] hover:w-48 transition-all ease-in-out duration-700 delay-75 fixed bottom-3 right-3"
    >
      <a
        class="flex flex-row justify-start"
        href="mailto:farrah.abidin@khazanah.com.my;nazmi.rahman@khazanah.com.my?cc=aliaa.nadzim@khazanah.com.my&amp;subject=Andromeda Feedback or Edit Data Request"
        title="Email us"
      >
        <div
          class="ml-3 opacity-0 group-hover:opacity-100 custom-transition font-bold text-[#0072C6] whitespace-nowrap"
        >
          Give us feedback
        </div>

        <img
          src="../assets/outlook.png"
          alt=""
          class="h-6 fixed bottom-6 right-6"
      /></a>
    </button>
  </div>
</template>

<script>
export default {
  name: "TheEmailBtn",
};
</script>

<style scoped>
.custom-transition {
  transition: opacity 0.3s ease-linear;
  transition-delay: 0s;
}
</style>
