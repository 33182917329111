import * as msal from "@azure/msal-browser"

export const APIURL = "https://aps-nddatabase-simple-prod-sea.azurewebsites.net"//"http://localhost:8000"//https://aps-nddatabase-dev-sea.azurewebsites.net //used for get request

//scopes created by Nazmi so that the front end has access to the back end
export const SCOPES = "user.read openid profile email offline_access api://a9318850-babc-4c96-a3f7-a26e87a16507/Files.Read"


const msalConfig = {
    auth: {
      clientId: "a0c56c83-7c48-4878-9289-8c08b87e05d3", // This is the ONLY mandatory field that you need to supply. this SPA client id
      authority: "https://login.microsoftonline.com/khazanahcommy.onmicrosoft.com", // Defaults to "https://login.microsoftonline.com/common"

      redirectUri:"https://andromeda.khazanah.com.my",//"https://gentle-glacier-0cbe13700.1.azurestaticapps.net", //"https://gentle-glacier-0cbe13700-dev.eastasia.1.azurestaticapps.net",//"http://localhost:3000", //
      //postLogoutRedirectUri: "", // Simply remove this line if you would like navigate to index page after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              // console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  };
  
  
  
  // exporting config object for jest
  if (typeof exports !== 'undefined') {
    module.exports = {
      msalConfig: msalConfig,
    };
  };


  
  
  
  export const myMSALObj = new msal.PublicClientApplication(msalConfig);