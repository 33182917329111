<template>
	<div class="bg-cover bg-gray-100 font-roboto" v-if="this.$store.state.authenticated">
		<TheHeader :view="viewRouter" />
		<div class="py-5">
			<router-view />
			<TheEmailBtn />
		</div>
	</div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheEmailBtn from "./components/TheEmailBtn.vue";

export default {
	components: { TheHeader, TheEmailBtn },
	data() {
		return {};
	},
	methods: {},
	computed: {
		viewRouter() {
			return this.$route.name;
		},
	},
	async created() {
		console.log(localStorage["retryCounter"])

		if (localStorage["retryCounter"] === undefined) {
			localStorage["retryCounter"] = 0
		}
		try {
			// throw new Error("simulate auth error")
			await this.$store.dispatch("getAuth");
			await this.$store.dispatch("getAllData", [
				this.$store.state.token,
				this.$store.state.username,
			]);
			localStorage["retryCounter"] = 0 //reset retryCounter
		}
		catch {
			if (localStorage["retryCounter"] == 0) {
				console.log("after throw Error",localStorage["retryCounter"])
				this.$store.dispatch("clearLocalStorage")
				await this.$store.dispatch("getAuth");
				await this.$store.dispatch("getAllData", [
					this.$store.state.token,
					this.$store.state.username,
				]);

			}
			// console.log("continue here after retry")
			localStorage["retryCounter"] = 1

		}

	},
};
</script>
