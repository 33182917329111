<template>
	<router-link to="/"
		><img src="../assets/nd_logo.svg" alt="logo" :class="logoStyle"
	/></router-link>
	<!-- class="object-scale-down h-11 w-11 mx-auto justify-center" -->
</template>

<script>
export default {
	name: "BaseLogo",
	props: {
		logoStyle: String,
	},
};
</script>
