<template>
	<div
		id="header"
		class="bg-white grid grid-cols-2 grid-rows-2 pt-1 md:grid-cols-10 md:grid-rows-1 md:pl-1"
	>
		<div
			id="left-header"
			class="flex col-start-1 col-span-9 items-center gap-x-1 flex-row justify-start md:col-span-6 md:gap-x-2"
		>
			<BaseKLogo logoStyle="h-20 w-21 hidden md:block" />
			<div id="a-logo">
				<img src="../assets/Advancing_Malaysia.png" alt="a-logo" class="h-12" />
			</div>
			<div id="logo and title" class="pl-8" v-show="otherView">
				<div class="items-center flex">
					<div id="logo" class="w-[80px] mb-3">
						<BaseLogo />
					</div>

					<div class="items-center content-center justify-center pl-1">
						<div
							class="text-3xl pb-2 text-[#70AD47] tracking-widest font-rosario text-center"
						>
							Andromeda
						</div>
						<router-link
							to="/"
							class="tracking-wider font-lilita text-gray-500 font-semibold text-base"
							>Talent Database</router-link
						>
					</div>
				</div>
			</div>
		</div>

		<div
			id="mid-header"
			class="flex col-start-1 row-start-2 col-span-10 flex-row items-center justify-center justify-items-center md:col-start-7 md:row-start-1 md:justify-end md:col-span-4 gap-4"
		>
			<ul
				class="font-roboto hover:text-secondBlue flex flex-row justify-between items-center gap-4"
			>
				<li class="text-firstBlue text-lg" :class="{ 'font-bold': this.view === 'home' }">
					<router-link class="hover:text-secondBlue" to="/">Home</router-link>
				</li>
				<h1 class="text-firstBlue text-lg">|</h1>
				<li class="text-firstBlue text-lg" >
					<a class="hover:text-secondBlue" href="https://app.powerbi.com/links/8T_lN8X0na?ctid=ce3bdff7-b7f6-4e7d-8b77-9085385980d2&pbi_source=linkShare" target="_blank">Stats</a>
				</li>
					
				<h1 class="text-firstBlue text-lg">|</h1>
				<li class="text-firstBlue text-lg">
					<router-link class="hover:text-secondBlue" to="/submission"
						>New Submission</router-link
					>
				</li>
			</ul>
			<div class="hidden md:block">
				<TheHeaderPP id="right-header" class="my-auto hidden md:block" />
			</div>
		</div>
	</div>
</template>

<script>
import BaseKLogo from "./BaseKLogo.vue";
import TheHeaderPP from "./TheHeaderPP.vue";
import BaseLogo from "./BaseLogo.vue";

export default {
	name: "TheHeader",
	props: {
		view: String,
	},
	computed: {
		otherView() {
			console.log(">>>>>view ", this.view);
			if (this.view == "home") {
				return false;
			} else return true;
		},
	},
	components: { TheHeaderPP, BaseKLogo, BaseLogo },
};
</script>
<style scoped>
a.router-link-exact-active {
	font-weight: bold;
}
</style>
