import { createStore } from "vuex";
import { myMSALObj, SCOPES, APIURL } from "../authConfig";
import "tw-elements";
import { orderBy } from "lodash-es";

//should set scope and apiurl in config and import it

export default createStore({
  state: {
    username: "",
    token: "",
    authenticated: false,
    ndList: [],
    searchResult: [],
    homeLoading: true,
    searchLoading: true,
    searchQuery: "",
    adminRole: false,
    filterArray: [],
    sortMethod: [
      { id: "name", label: "Name", checked: true, order: "asc" },
      { id: "date", label: "Upload Date", checked: false, order: "desc" },
      { id: "age", label: "Age", checked: false, order: "asc" },
      {
        id: "@search.score",
        label: "Relevancy",
        checked: false,
        order: "desc",
      },
    ],
    view: "View.User",
    checkboxExport: [],
    activeID: "",
  },
  getters: {},
  mutations: {
    setUsername(state, value) {
      state.username = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setView(state, value) {
      state.view = value;
    },
    setHomeLoading(state, value) {
      state.homeLoading = value;
    },
    setSearchLoading(state, value) {
      state.searchLoading = value;
    },
    setNDList(state, value) {
      value = value.sort((a, b) => {
        return parseInt(b.id) - parseInt(a.id);
      });
      state.ndList = value;
    },
    setSearchResult(state, value) {
      state.searchResult = value;
    },
    setSearchQuery(state, value) {
      state.searchQuery = value;
    },
    setAdminRole(state, value) {
      state.adminRole = value;
    },
    setFilterArray(state, value) {
      state.filterArray = value;
    },
    setFilterItem(state, id) {
      state.filterArray[id].checked = !state.filterArray[id].checked;
    },
    clearFilterItem(state, id) {
      state.filterArray[id].checked = false;
    },
    clearSortItem(state, id) {
      state.sortMethod[id].checked = false;
    },
    setSortMethod(state, id) {
      state.sortMethod.forEach((el, index) => {
        //reset ...
        state.sortMethod[index].checked = false;
      });
      state.sortMethod[id].checked = true;
    },
    initCheckboxExport(state, value) {
      state.checkboxExport = value;
    },
    setNDListCheckboxExport(state, id) {
      state.ndList[id].checked = !state.ndList[id].checked;
    },
    setActiveID(state, value) {
      state.activeID = value;
    },
  },
  actions: {
    clearLocalStorage({ commit }) {
      console.log("clear Local Storage except retry Counter");
      var keyArr = Object.entries(localStorage).filter(([key, value]) => {
        return key != "retryCounter";
      });
      // console.log("key Arr", keyArr);
      keyArr.forEach((key) => {
        delete localStorage[key[0]];
      });
    },
    async getAuth({ commit }) {
      await myMSALObj.handleRedirectPromise();
      var accounts = myMSALObj.getAllAccounts();
      if (accounts.length === 0) {
        // No user signed in
        await myMSALObj.loginRedirect();
      }
      //find username
      await myMSALObj
        .acquireTokenSilent({
          account: myMSALObj.getAccountByUsername(accounts[0].username),
          scopes: [SCOPES],
        })
        .then((response) => {
          commit("setToken", response.idToken);
          commit("setUsername", accounts[0].username);
          commit("setAuthenticated", true);
          if (response.account.idTokenClaims.roles.includes("View.Admin")) {
            commit("setView", "View.Admin");
          } else {
            commit("setView", "View.User");
          }
        });
    },
    async getToken({ commit }, username) {
      const res = await myMSALObj.acquireTokenSilent({
        account: myMSALObj.getAccountByUsername(username),
        scopes: [SCOPES],
      });
      const data = res.idToken; //pass idtoken
      const roleArray = res.account.idTokenClaims.roles;
      // check if exist View.Admin , else View.User
      if (roleArray.includes("View.Admin")) {
        commit("setView", "View.Admin");
      } else {
        commit("setView", "View.User");
      }
      commit("setToken", data);
    },
    getFilterArray({ commit, state }) {
      let filter = []; //array of objects
      let tempGender = [];
      let tempEthnicity = [];
      let tempNationality = [];
      let tempKAlumni = [];
      let tempRecommended = [];
      let tempAgeRange = [
        {
          type: "ageRange",
          label: "below 40",
          checked: false,
          id: "age-below40",
        },
        {
          type: "ageRange",
          label: "40 to 69",
          checked: false,
          id: "age-below70",
        },
        {
          type: "ageRange",
          label: "70 and above",
          checked: false,
          id: "age-above70",
        },
        {
          type: "ageRange",
          label: "N/A",
          checked: false,
          id: "age-unknown",
        },
      ];
      let tempReviewed = [];
      let tempArchived = [];
      let tempAuthor = [];
      let tempIvTeam = [];
      let tempKND=[];



      state.ndList.forEach((el) => {
        //extract all gender first and all ethnicity
        tempGender.push(el.gender);
        tempEthnicity.push(el.ethnicity);
        tempNationality.push(el.nationality);
        tempKAlumni.push(el.k_alumni);
        tempReviewed.push(el.reviewed); //
        tempArchived.push(el.archived);
        tempRecommended.push(el.is_recommended); //need to tweak if undefined the n it is false
        tempAuthor.push(el.email_author);
        tempIvTeam.push(el.iv_team);
        tempKND.push(el.k_nd[0].value)
      });
      tempGender = [...new Set(tempGender)].map((el) => {
        return {
          type: "gender",
          label: el,
          checked: false,
          id: "gender".concat("-", el),
        };
      });

      // sort here

      tempEthnicity = orderBy(
        [...new Set(tempEthnicity)].map((el) => {
          return {
            type: "ethnicity",
            label: el,
            checked: false,
            id: "ethnicity".concat("-", el),
          };
        }),
        "label",
        "asc"
      );
      tempNationality = [...new Set(tempNationality)].map((el) => {
        return {
          type: "nationality",
          label: el,
          checked: false,
          id: "nationality".concat("-", el),
        };
      });

      tempReviewed = [...new Set(tempReviewed)].map((el) => {
        return {
          type: "reviewed",
          label: el,
          checked: false,
          id: "reviewed".concat("-", el),
        };
      });
      tempArchived = [...new Set(tempArchived)].map((el) => {
        return {
          type: "archived",
          label: el,
          checked: false,
          id: "archived".concat("-", el),
        };
      });

      tempRecommended = [...new Set(tempRecommended)].map((el) => {
        //el is string
        return {
          type: "recommended",
          label: String(el),
          checked: false,
          id: "recommended".concat("-", String(el)),
        };
      });

      tempKND = orderBy(
        [...new Set(tempKND)].map((el) => {
          return {
            type: "k_nd",
            label: el,
            checked: false,
            id: "k_nd".concat("-", el),
          };
        }),
        "label",
        "asc"
      );

      tempKAlumni = orderBy(
        [...new Set(tempKAlumni)].map((el) => {
          return {
            type: "k_alumni",
            label: el,
            checked: false,
            id: "k_alumni".concat("-", el),
          };
        }),
        "label",
        "asc"
      );

      tempAuthor = [...new Set(tempAuthor)]
        .filter((item) => item !== null && item !== undefined && item !== "")
        .sort();
      tempAuthor = tempAuthor.map((el) => {
        return {
          type: "author",
          label: el,
          checked: false,
          id: el,
        };
      });
      tempIvTeam = tempIvTeam.filter(
        (item) => item !== null && item !== undefined && item !== ""
      );
      tempIvTeam = orderBy(
        [...new Set(tempIvTeam)].map((el) => {
          return {
            type: "iv_team",
            label: el,
            checked: false,
            id: "iv_team".concat("-", el),
          };
        }),
        "label",
        "asc"
      );

      filter = [
        ...tempGender,
        ...tempEthnicity,
        ...tempNationality,
        ...tempKAlumni,
        ...tempAgeRange,
        ...tempReviewed,
        ...tempArchived,
        ...tempAuthor,
        ...tempIvTeam,
        ...tempRecommended,
        ...tempKND
      ];
      commit("setFilterArray", filter);
    },
    getCheckboxExport({ commit, state }) {
      //id and checked
      var tempArray = [];
      state.ndList.forEach((el) => {
        tempArray.push({ id: el.id, checked: false });
      });
      // console.log(tempArray)
      commit("initCheckboxExport", tempArray);
    },

    async trackHome({ commit, dispatch, state }) {
      try {
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
        myHeaders.append("username", state.username); // send data to backend
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
        };

        const res = await fetch(`${APIURL}/track-home`, requestOptions);
      } catch (error) {
        console.warn(error);
      }
    },

    async getAllData({ commit, dispatch, state }, [token, username]) {
      commit("setHomeLoading", true);
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
        myHeaders.append("username", username); // send data to backend
        var requestOptions = { headers: myHeaders };

        const res = await fetch(`${APIURL}/get-all`, requestOptions);
        // console.log(res.status);
        if (res.status == 401) {
          throw new Error(res.status);
        }

        const data = await res.json();

        //add this year age
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        data.forEach((el, index) => {
          // need to refactor this
          let age = currentYear - el.birth_year;
          if (el.birth_year == null) {
            age = "N/A";
          }
          // data[index]["checked"]= false
          data[index]["age"] = age;

          switch (true) {
            case age < 40:
              data[index]["ageRange"] = "below 40";
              break;
            case age < 70:
              data[index]["ageRange"] = "40 to 69";
              break;
            case age < 130:
              data[index]["ageRange"] = "70 and above";
              break;
            default:
              data[index]["ageRange"] = "N/A";
          }

          switch (true) {
            case data[index]["archived"] == true:
              data[index]["archived"] = "Archived";
              break;
            case data[index]["archived"] == false:
              data[index]["archived"] = "Live";
              break;
          }

          switch (true) {
            case data[index]["is_recommended"] == true:
              data[index]["is_recommended"] = "Executive/ Senior Management";
              break;
            case data[index]["is_recommended"] == false:
              data[index]["is_recommended"] = "Director";
              break;
            case data[index]["is_recommended"] == undefined:
              data[index]["is_recommended"] = "Director";
              break;
          }

          switch (true) {
            case data[index]["reviewed"] == true:
              data[index]["reviewed"] = "Reviewed";
              break;
            case data[index]["reviewed"] == false:
              data[index]["reviewed"] = "Pending";
              break;
          }

          let presetEthnicity = ["Malay", "Chinese", "Indian"];
          if (!presetEthnicity.includes(data[index]["ethnicity"])) {
            data[index]["ethnicity"] = "Other";
          }

          //if no iv_team then set " "
          if (data[index]["iv_team"] === undefined) {
            data[index]["iv_team"] = "";
          }
        });

        commit("setNDList", data);
        commit("setActiveID", "");
        dispatch("trackHome");
        dispatch("getFilterArray");
        dispatch("getCheckboxExport");
        commit("setHomeLoading", false);
      } catch (error) {
        console.warn(error);

        //retry function
        commit("setReportList", {});
      }
    },

    async trackSearch({ state }, searchQuery) {
      var body = { query: searchQuery };
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
      };

      const res = await fetch(`${APIURL}/track-search`, requestOptions);
    },

    async getSearchResult({ commit, dispatch, state }, searchQuery) {
      commit("setSearchLoading", true); //start skeleton loading
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
        myHeaders.append("username", state.username); // send data to backend
        var requestOptions = { headers: myHeaders };

        if (!state.ndList.length) {
          // if ndList is empty then getAll
          dispatch("getAllData", [state.token, state.username]);
        }

        const res = await fetch(
          `${APIURL}/search-table/${searchQuery}`,
          requestOptions
        );
        if (res.status == 401) {
          throw new Error(response.status);
        }
        let data = await res.json();

        // filter the data from nDList and store here

        // get array of id in search
        let dataIDResult = data.map((el) => {
          return el["id"];
        }); // return flat array

        data = state.ndList.filter((el) => {
          return dataIDResult.includes(el["id"]);
        });

        //add this year age
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        //map data differently
        data.forEach((el, index) => {
          let age = currentYear - el.birth_year;
          data[index]["age"] = age;

          switch (true) {
            case age < 40:
              data[index]["ageRange"] = "below 40";
              break;
            case age < 70:
              data[index]["ageRange"] = "40 to 69";
              break;
            case age < 130:
              data[index]["ageRange"] = "70 and above";
              break;
            default:
              data[index]["ageRange"] = "N/A";
          }

          switch (true) {
            case data[index]["archived"] == true:
              data[index]["archived"] = "Archived";
              break;
            case data[index]["archived"] == false:
              data[index]["archived"] = "Live";
              break;
          }

          switch (true) {
            case data[index]["reviewed"] == true:
              data[index]["reviewed"] = "Reviewed";
              break;
            case data[index]["reviewed"] == false:
              data[index]["reviewed"] = "Pending";
              break;
          }

          let presetEthnicity = ["Malay", "Chinese", "Indian"];
          if (!presetEthnicity.includes(data[index]["ethnicity"])) {
            data[index]["ethnicity"] = "Other";
          }
        });
        //console.log(data);
        commit("setSearchResult", data); //store the data in global var
        dispatch("trackSearch", searchQuery);
        commit("setSearchLoading", false); // stop skeleton Loading
        commit("setActiveID", "");
      } catch (error) {
        //if there is any error like expired token,
        console.warn(error);
        commit("setSearchResult", {}); //
      }
    },
    resetFilter({ commit, dispatch, state }) {
      state.filterArray.forEach((el, index) => {
        commit("clearFilterItem", index);
      });
    },

    async trackNewSubmission({ state }) {
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      const res = await fetch(`${APIURL}/track-newsubmission`, requestOptions);
    },

    async trackEditSubmission({ state }) {
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      const res = await fetch(`${APIURL}/track-editsubmission`, requestOptions);
    },

    async trackRating({ state }, [score, comment]) {
      var body = { score: score, comment: comment };

      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(body),
      };

      const res = await fetch(`${APIURL}/track-rating`, requestOptions);
    },

    async trackExportButton({ state }) {
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      const res = await fetch(`${APIURL}/track-export`, requestOptions);
    },

    async downloadAttachment({ state, dispatch }, [id, fileName]) {
      document.body.style.cursor = "wait";
      await dispatch("getAuth");
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      const res = await fetch(
        `${APIURL}/get-blob-by-name/${id}.pdf`,
        requestOptions
      );
      if (res.ok) {
        const blob = await res.blob();
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName + ".pdf");
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
      }
      document.body.style.cursor = "default";
    },
  },
  modules: {},
});
