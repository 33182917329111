<template>
    <div class="flex flex-col">

        <div v-for="choice in choiceList">
            <label class="inline-flex items-center">
                <input type="checkbox" class="form-checkbox" :value="choice" v-model="answer">
                <span class="ml-2">{{ choice }}</span>
            </label>
        </div>

        <label class="inline-flex items-center" v-if=hasOther>
            <input type="checkbox" class="form-checkbox" v-model="otherAnswerBool" >
            <span class="ml-2"> Others: Please specify below.</span>
        </label>


        <div v-if="otherAnswerBool" class="mt-1">
            <div v-for="item in getObjectList" :key="item['id']" class="flex flex-row gap-x-1">

                <BaseMultiValueTextInput :id="item['id']" :item="item" @deleteItem="deleteItem"
                    @oneValueChange="setValueByID" class="w-1/2 mx-1" :showDel=showDel />
            </div>
            <button type="button" class="flex space-x-2 justify-start mt-3" @click="addItem">
                <div class="rounded-lg  text-white bg-firstBlue px-3 mx-1">
                    + Add More
                </div>
                <!-- <p>Add More</p> -->
            </button>
        </div>




    </div>

</template>

<script>
import BaseMultiValueTextInput from '../components/BaseMultiValueTextInput.vue'
export default {

    name: 'BaseCheckBoxSkillSet',
    components: { BaseMultiValueTextInput },
    props: {
        hasOther: Boolean,
        choiceList: Array,
        formMode: String,
        editDataAnswer: Array,
        editDataOther: Array
    },
    data() {
        return {
            answer: [],
            otherAnswerBool: "",
            // otherSkillSet: "",
            objectList: [{ "id": 100, "value": "" }],
            counter: 100


        }
    },
    computed: {
        output() {

            const output = { "answer": this.answer, "other": this.objectList, "otherBool": this.otherAnswerBool }
            return output

        },
        getObjectList() {
            return this.objectList
        },
        showDel(){
      if (this.objectList.length>1){
        return true
      }else{ return false}
    }

    },
    methods: {

        addItem() {
            this.counter++
            this.objectList.push({ "id": this.counter, "value": "" })//when click add item, add empty value first


        },
        deleteItem(itemID) {
            this.objectList = this.objectList.filter(el => {

                return (el["id"] != itemID)
            })
        },
        setValueByID(item) {
            //need to change this method to cater for other fields
            const index = this.objectList.findIndex(el => {
                return el["id"] == item["id"]
            })
            if (index !== -1) {
                //
                this.objectList[index]["value"] = item["value"]
            }

        },

    },
    watch: {
        // output() {
        //     this.$emit('answerChange', this.output)
        // },
        // objectList: {
        //     handler(newValue, oldValue) {
        //         // console.log(newValue)
        //         this.$emit("multiValueChange", newValue)
        //     }, deep: true


        // }
        output: {
            handler(newValue, oldValue) {
                // console.log(newValue)
                this.$emit("answerChange", newValue)
            }, deep: true


        },
        otherAnswerBool(){
            if (this.otherAnswerBool == false){
                this.objectList = [{ "id": 100, "value": "" }] // reset the objectList if uncheck
            }
        }


    },
    created() {
        if (this.formMode == "Edit") {
            this.answer = this.editDataAnswer
            if (this.editDataOther.length > 0) {
                this.otherAnswerBool = true
                // this.otherSkillSet = this.editDataOther // to change here
                this.objectList = this.editDataOther
            } else {
                this.otherAnswerBool = false
            }
        }
    }
}

// need a way to pass data somewhere

</script>