import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import EditView from "../views/EditView.vue";
import SubmissionView from "../views/SubmissionView.vue";
import SearchView from "../views/SearchView.vue";
// import store from '../store'

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
   { path: "/code=:pathMatch(.*)*", name: "others", redirect: "/" },
  
  {
    path: "/search",
    name: "SearchView",
    component: SearchView,
  },
  {
    path: "/edit",
    name: "EditView",
    component: EditView,
  },
  {
    path: "/submission",
    name: "SubmissionView",
    component: SubmissionView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return { top: 0 }
  }
});

// router.beforeEach((to,from)=>{
//   // console.log(from)
//   store.commit("setPrevLink",from.fullPath)
// })

export default router;
