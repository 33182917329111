<template>
    <div class="flex flex-col">

        <div v-for="choice in choiceList">
            <label class="inline-flex items-center">
                <input type="checkbox" class="form-checkbox" :value="choice" v-model="answer">
                <span class="ml-2">{{choice}}</span>
            </label>
        </div>

        <label class="inline-flex items-center" v-if=hasOther>
            <input type="checkbox" class="form-checkbox" v-model="otherAnswerBool">
            <label class="block">
                <input class="form-input mt-1 ml-2 block w-full h-8" placeholder="Other" v-model="otherSkillSet">
            </label>
        </label>



    </div>

</template>
    
<script>
export default {

    name: 'BaseCheckBoxOther',
    props: {
        hasOther: Boolean,
        choiceList: Array,
        formMode:String,
        editDataAnswer:Array,
        editDataOther:String
    },
    data() {
        return {
            answer: [],
            otherAnswerBool: "",
            otherSkillSet:""
            
            
        }
    },
    computed: {
        output() {

            const output = {"answer":this.answer,"other":this.otherSkillSet,"otherBool":this.otherAnswerBool}
            return output
 
        }

    },
    methods: {

    },
    watch: {
        output() {
            this.$emit('answerChange', this.output)
        }
    },
    created(){
        if(this.formMode =="Edit"){
            this.answer = this.editDataAnswer
            if(this.editDataOther.length>0){
                this.otherAnswerBool = true
                this.otherSkillSet=this.editDataOther
            }else{
                this.otherAnswerBool = false
            }
        }
    }
}

// need a way to pass data somewhere

</script>