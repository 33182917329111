<template>
    <div class="flex flex-col">

        <select name="dropdown" v-model="answer" class="block w-full mt-1 mx-1 border-none rounded-lg">
                <option value="" default selected class="">{{placeholder}}</option>
                <option v-for="choice in choiceList" :value="choice">{{choice}}</option>
            
        </select>
        

        
    </div>

</template>
    
<script>
export default {

    name: 'TeamDropdown',
    props: {
        placeholder:String,
        choiceList: Array,
        formMode:String,
        editDataAnswer:String,
        
    },
    data() {
        return {
            answer:""
        }
    },


    watch: {
        answer() {
            this.$emit('answerChange', this.answer.toString())
        }
    },
    created(){
        //must change
        if(this.formMode =="Edit"){
            this.answer = this.editDataAnswer
        }
    }
}


</script>