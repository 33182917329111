<template>
    <div class="flex flex-col">
        <div v-for="choice in choiceList">
            <label class="inline-flex items-center">
                <input v-model="answer" type="radio" class="form-radio" :name="category" :value="choice">
                <span class="ml-2">{{choice}}</span>
            </label>
        </div>

        <label class="inline-flex items-center" v-if="hasOther">
            <input v-model="answer" type="radio" class="form-radio" :name="category" value="Other">
            <label class="block">
                <input v-model="otherAnswer" class="form-input mt-1 ml-2 block h-8 w-10/12 rounded-lg border-none" placeholder="Other">
            </label>
        </label>
    
    </div>


</template>
    
<script>
export default {

    name: 'BaseRadioOther',
    props: {
        hasOther: Boolean,
        choiceList: Array,
        category:String,
        editData:String,
        formMode:String


        
    },
    
    data() {
        return {
            answer: "",
            otherAnswer: ""
        }
    },
    computed: {
        output() {
            if (this.answer == "Other") {
                // this.$emit('answerChange',this.otherAnswer)
                
                return this.otherAnswer
            } else {
                // this.$emit('answerChange',this.answer)
                
                return this.answer
            }
        }

    },
    methods:{

    },
    watch:{
        output(){
            this.$emit('answerChange',this.output)
        }
    },
    created(){
        if(this.formMode=="Edit"){
            if(this.choiceList.includes(this.editData)){
                this.answer=this.editData
                this.otherAnswer=""
            }else{
                this.answer="Other"
                this.otherAnswer=this.editData
            }
        }
    }
}

// need a way to pass data somewhere

</script>