<template>
  <div class="my-5 px-6">
    <section v-show="!showLoading" class="text-gray-800">
      <div class="flex flex-wrap gap-6">
        <p class="font-semibold text-justify">
          The Andromeda Talent Database is a database of Khazanah’s potential
          independent directors, nominee directors (ND) and senior management
          candidates for our investee companies that is built in a web-app.
          Details of the talent pool include name, education, employment history
          and etc. We invite the M&M Teams and any staff approved for access to
          contribute credible candidates and collectively construct the
          database. Please fill out the form below:

          <br />
          <br />
          <span class="font-normal italic">* indicates required</span>
        </p>

        <div
          id="form-container"
          class="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full px-3"
        >
          <form
            class="flex flex-col gap-10"
            @submit.prevent
            v-on:keydown.enter.prevent
          >
            <div>
              <span class="text-gray-700 font-semibold">Submission by:</span>

              <div class="flex flex-row items-center">
                <span>Email: </span>
                <input
                  v-model="emailAuthor"
                  class="form-input rounded-lg w-full border-none bg-gray-100 italic"
                  disabled
                  placeholder=""
                />
              </div>
              <div class="flex flex-row items-center">
                <span>Team: </span>
                <TeamDropdown
                  class=""
                  :formMode="formMode"
                  :editDataAnswer="ivTeam"
                  :answer="ivTeam"
                  :choiceList="teamChoice"
                  placeholder=""
                  @answerChange="setData($event, 'ivTeam')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold">Honorific*</span>
              <div id="honorific-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="honorificOutput"
                  :choiceList="honorificChoice"
                  :hasOther="true"
                  category="honorific"
                  @answerChange="setData($event, 'honorificOutput')"
                />
              </div>
            </div>

            <label class="block">
              <span class="text-gray-700 font-semibold">Candidate Name*</span>
              <input
                v-model.trim="candidateName"
                class="form-input mt-1 block rounded-lg w-full border-none"
                placeholder=""
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold">Year of Birth</span>
              <p class="italic text-sm">
                Please leave it blank if no information is available
              </p>
              <input
                v-model.number="birthYear"
                class="form-input mt-1 block rounded-lg w-full border-none"
                placeholder=""
              />
            </label>

            <div class="block">
              <span class="text-gray-700 font-semibold">Gender*</span>
              <div id="gender-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="genderOutput"
                  :choiceList="genderChoice"
                  :hasOther="false"
                  category="gender"
                  @answerChange="setData($event, 'genderOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold">Nationality*</span>
              <div id="nationality-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="nationalityOutput"
                  :choiceList="nationalityChoice"
                  :hasOther="false"
                  category="nationality"
                  @answerChange="setData($event, 'nationalityOutput')"
                />
              </div>
            </div>

            <div class="block" v-if="branchNationalityOutput">
              <span class="text-gray-700 font-semibold"
                >Ethnicity for Malaysian*</span
              >
              <div id="ethnic-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="ethnicityOutput"
                  :choiceList="ethnicityChoice"
                  :hasOther="true"
                  category="ethnicity"
                  @answerChange="setData($event, 'ethnicityOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Country of Residence*
              </span>
              <div id="country-form">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="countryOutput"
                  :choiceList="countryChoice"
                  :hasOther="true"
                  category="country"
                  @answerChange="setData($event, 'countryOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Is Khazanah Alumni?*
              </span>
              <div id="test">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="kAlumniOutput"
                  :choiceList="kAlumniChoice"
                  :hasOther="false"
                  category="isKAlumni"
                  @answerChange="setData($event, 'kAlumniOutput')"
                />
              </div>
            </div>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Current Khazanah ND?*
              </span>
              <div id="test">
                <BaseRadioOther
                  :formMode="formMode"
                  :editData="currentNDOutput"
                  :choiceList="currentNDChoice"
                  :hasOther="false"
                  category="isKND"
                  @answerChange="setData($event, 'currentNDOutput')"
                />
              </div>
            </div>

            <label class="block" v-if="branchCurrentNDOutput">
              <span class="text-gray-700 font-semibold"
                >Name of Investee Co or SPV on which the individual is KNB's
                ND*</span
              ><br />
              <span>e.g: edotco Group Sdn Bhd</span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="ndCompanyOutput"
                @multiValueChange="setData($event, 'ndCompanyOutput')"
              />
            </label>

            <label class="block" v-if="branchCurrentNDOutput">
              <span class="text-gray-700 font-semibold">Asset Class*</span
              ><br />
              <span>e.g: Telco & Media</span>
              <BaseMultiValue
                :formMode="formMode"
                :editData="assetClassOutput"
                @multiValueChange="setData($event, 'assetClassOutput')"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Education Level, Discipline</span
              ><br />
              <span>e.g: </span><br />
              <span>MBA; Harvard Business School</span><br />
              <span>
                Bachelor of Arts (Magna Cum Laude) in East Asian Language and
                Civilizations (Japanese) and Economics; Harvard College</span
              >

              <BaseMultiValue
                :formMode="formMode"
                :editData="educationOutput"
                @multiValueChange="setData($event, 'educationOutput')"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Professional Qualification</span
              ><br />
              <span>e.g: Association of Chartered Certified Accountants</span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="qualificationOutput"
                @multiValueChange="setData($event, 'qualificationOutput')"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Professional Membership</span
              ><br />
              <span>e.g: Malaysian Institute of Accountants</span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="membershipOutput"
                @multiValueChange="setData($event, 'membershipOutput')"
              />
            </label>
            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Current Position(s)</span
              ><br />
              <span>e.g: </span><br />
              <span>NINE - Non independent non executive director;</span><br />
              <span>INE - Independent non executive director; </span> <br />
              <span>CEO, CIO, CTO, CHRO; </span> <br />
              <span>Advisor; etc.</span> <br />

              <BaseMultiValue2
                :formMode="formMode"
                @multiValueChange="setData($event, 'currentPositionOutput')"
                :isUntilPresent="false"
                :editData="currentPositionOutput"
              />
            </label>
            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Previous Employment, Designation and Company Name*</span
              ><br />
              <span>e.g: </span><br />
              <span
                >2013 – Present: Board member & Board Audit Committee, XYZ
                Group</span
              ><br />
              <span
                >2011 – 2018: Executive Director, Khazanah (Telco, Media, L&T
                and Education); Director & Board
              </span>

              <BaseMultiValue2
                :formMode="formMode"
                @multiValueChange="setData($event, 'previousEmploymentOutput')"
                :isUntilPresent="true"
                :editData="previousEmploymentOutput"
              />
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold"
                >Industries or sectors of previous employments*</span
              ><br />
              <span></span>

              <BaseMultiValue
                :formMode="formMode"
                :editData="previousIndustriesOutput"
                @multiValueChange="setData($event, 'previousIndustriesOutput')"
              />
            </label>

            <div class="block">
              <span class="text-gray-700 font-semibold"
                >Candidate Skill Set*</span
              >

              <BaseCheckBoxSkillSet
                :formMode="formMode"
                :editDataAnswer="skillOutput"
                :editDataOther="skillOutputOther"
                :hasOther="true"
                :choiceList="skillSetChoice"
                @answerChange="
                  setDataCheckbox($event, ['skillOutput', 'skillOutputOther'])
                "
              />
            </div>

            <label class="block">
              <span class="text-gray-700 font-semibold">Team Comments</span
              ><br />
              <span
                >Example: <br />
                (i) High alignment with Khazanah in driving changes to achieve
                our objectives <br />
                (ii) proven track record and skills in restructuring and legal
                framework <br />
                (iii) Able to draw upon past experience to meet the company's
                business needs (e.g. board audit committee and M&A experience)
                <br />
                (iii) Valuable insights related to industry XYZ</span
              >
              <textarea
                class="form-textarea mt-1 block w-full border-none rounded-lg"
                rows="5"
                placeholder="Comments."
                v-model="comments"
              ></textarea>
            </label>

            <label class="block">
              <span class="text-gray-700 font-semibold">LinkedIn URL</span>
              <input
                class="form-input mt-1 block rounded-lg w-full border-none"
                placeholder=""
                v-model="url"
              />
            </label>

            <!-- attachment  -->

            <div class="-mb-10">
              <label
                for="user_avatar"
                class="bg-firstBlue text-white font-normal px-3 py-1 my-3 rounded-lg flex hover:bg-secondBlue hover:cursor-pointer w-56"
              >
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z"
                    />
                    <path
                      d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z"
                    />
                  </svg>
                  <span class="ml-1">Upload Profile Picture </span>
                </div>
              </label>
              <img
                :src="getPP"
                class="h-14 w-14 rounded-full mx-2 mt-1"
                alt=""
                v-show="getPP != ''"
              />
              <input
                class="mt-1 block h-0 w-full text-sm text-gray-900 rounded-lg border-none cursor-pointer focus:outline-none opacity-0"
                aria-describedby="user_avatar_help"
                id="user_avatar"
                type="file"
                accept=".jpg, .png"
                @change="encodeImageFileAsURL"
                ref="file"
              />
            </div>

            <div class="upload-attachments">
              <label
                for="attachment"
                class="bg-firstBlue text-white font-normal px-3 py-1 my-3 rounded-lg flex hover:bg-secondBlue hover:cursor-pointer w-56"
              >
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <span class="ml-1">Upload PDF </span>
                </div>
              </label>

              <input
                class="mt-1 block h-0 w-full text-sm text-gray-900 rounded-lg border-none cursor-pointer focus:outline-none opacity-0"
                aria-describedby="pdf_attachment"
                id="attachment"
                type="file"
                accept="application/pdf"
                @change="encodeFileAsBlob"
                ref="pdffile"
              />
              <div id="downloadLinkContainer">{{ pdfFileName }}</div>
              <!-- when edit need to be clickable -->
            </div>

            <div
              id="recommendation-check-box"
              class=""
              v-if="formMode == 'Edit'"
            >
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="isRecommended"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z"
                  />
                </svg>

                <span class="ml-2 font-semibold"
                  >Recommended for Executive/Senior Management Positions</span
                >
              </label>
            </div>

            <!-- add approve button  -->
            <div id="approve-check-box" class="" v-if="formMode == 'Edit'">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="reviewed"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="ml-2 font-semibold">Reviewed by IMO</span>
              </label>
            </div>
            <div id="archived-check-box" class="" v-if="formMode == 'Edit'">
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="archived"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 ml-2"
                >
                  <path
                    d="M2 3a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1V4a1 1 0 00-1-1H2z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2 7.5h16l-.811 7.71a2 2 0 01-1.99 1.79H4.802a2 2 0 01-1.99-1.79L2 7.5zm5.22 1.72a.75.75 0 011.06 0L10 10.94l1.72-1.72a.75.75 0 111.06 1.06L11.06 12l1.72 1.72a.75.75 0 11-1.06 1.06L10 13.06l-1.72 1.72a.75.75 0 01-1.06-1.06L8.94 12l-1.72-1.72a.75.75 0 010-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="ml-2 font-semibold">Archived</span>
              </label>
            </div>
            <button
              v-show="!showProcessButton"
              type="submit"
              class="w-full px-6 py-2.5 bg-firstBlue text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-secondBlue hover:shadow-lg focus:bg-secondBlue focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondBlue active:shadow-lg transition duration-150 ease-in-out disabled:opacity-50"
              @click="onSubmit"
              :disabled="isBtnDisable"
            >
              Send
            </button>

            <button
              v-show="showProcessButton"
              type="button"
              class="inline-flex items-center place-content-center w-full px-6 py-2.5 bg-firstGray text-white font-medium text-xs leading-tight uppercase rounded shadow-md"
              disabled
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              Processing...
            </button>

            <button
              v-show="showDeleteButton && formMode == 'Edit'"
              type="submit"
              class="w-full px-6 py-2.5 -my-7 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-secondBlue hover:shadow-lg focus:bg-secondBlue focus:shadow-lg focus:outline-none focus:ring-0 active:bg-secondBlue active:shadow-lg transition duration-150 ease-in-out disabled:opacity-50"
              @click="onDelete"
              :disabled="isBtnDisable"
            >
              Delete
            </button>

            <button
              v-show="!showDeleteButton && formMode == 'Edit'"
              type="button"
              class="inline-flex items-center place-content-center w-full px-6 py-2.5 -my-7 bg-red-300 text-white font-medium text-xs leading-tight uppercase rounded shadow-md"
              disabled
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              Processing...
            </button>
          </form>
        </div>
      </div>
    </section>

    <div
      v-if="showLoading"
      class="flex flex-col items-center justify-center space-x-2 h-screen"
    >
      <div
        class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="my-1">Loading...</span>
    </div>
  </div>
</template>

<script>
import BaseMultiValue from "./BaseMultiValue.vue";
import BaseMultiValue2 from "./BaseMultiValue2.vue";
import BaseRadioOther from "./BaseRadioOther.vue";
import BaseCheckBoxOther from "./BaseCheckBoxOther.vue";
import BaseCheckBoxSkillSet from "./BaseCheckBoxSkillSet.vue";
import { v4 as uuidv4 } from "uuid";
import { APIURL } from "../authConfig";
import { cloneDeep } from "lodash-es";
import BaseDropdown from "./BaseDropdown.vue";
import TeamDropdown from "./TeamDropdown.vue";

export default {
  name: "TheForm",
  data() {
    return {
      reviewed: "",
      isRecommended: "",
      ivTeam: "",
      id: "",
      candidateName: "",
      birthYear: "",
      comments: "",
      url: "",
      honorificOutput: "",
      genderOutput: "",
      nationalityOutput: "",
      ethnicityOutput: "",
      countryOutput: "",
      currentNDOutput: "",
      kAlumniOutput: "",
      ndCompanyOutput: [],
      assetClassOutput: [],
      educationOutput: [],
      qualificationOutput: [], //
      membershipOutput: [],
      currentPositionOutput: [], //
      previousEmploymentOutput: [],
      previousIndustriesOutput: [],
      skillOutput: [], //checkbox
      skillOutputOther: [], //checkbox should be list then combine with the item above but id need to be unique???
      b64img: "",
      pdfFile: "",
      pdfFileName: "",
      honorificChoice: ["Mr.", "Ms.", "Dato'", "Datuk", "Dato' Sri", "Tan Sri"],
      genderChoice: ["Male", "Female"],
      nationalityChoice: ["Malaysian", "Non-Malaysian"],
      ethnicityChoice: ["Malay", "Chinese", "Indian"],
      countryChoice: ["Malaysia", "UK or EU"],
      currentNDChoice: ["Yes", "No"],
      kAlumniChoice: ["Yes", "No"],
      skillSetChoice: [
        "Restructuring",
        "Financial Management",
        "Sales and Marketing",
        "Legal",
        "Sustainability / ESG",
        "Risk Management",
        "Human Resources",
        "Accounting",
        "Operations",
        "Digital and Technology",
        "Real Estate",
      ],
      teamChoice: [
        "Telco & Media",
        "Infrastructure & Airlines",
        "TM",
        "Telco & Media",
        "TdC",
        "Energy",
        "Investment Support – HRBP",
        "Investment Support – Compliance",
        "Energy, Iskandar & L&T",
        "Private Markets & Technology",
        "Public Markets",
        "Dana Impak & Healthcare",
        "Investment Support - Asset Allocation",
        "Investment Support – IMSS",
        "Iskandar & CAM",
        "Active Opportunities Portfolio",
        "Private – SEA",
        "IHH & ReGen",
        "Leisure & Tourism",
        "Sentinel",
        "Consumer",
        "Healthcare",
        "Technology",
        "Airlines",
        "FIG",
        "Real Asset",
        "Private Fund",
        "Dana Impak",
        "Infrastructure",
        "Public - HQ KL",
      ],

      error: [],
      archived: "",
      hasAttachment: false,
      showLoading: false,
      showProcessButton: false,
      showDeleteButton: true,
      isBtnDisable: false,
      emailAuthor: "",
      emailEditor: "",
      createdDate: "",
    };
  },
  props: {
    formMode: String,
  },
  computed: {
    formatDateNow() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getNDCompanyList() {
      return this.ndCompanyList;
    },
    branchNationalityOutput() {
      return this.nationalityOutput == "Malaysian";
    },
    branchCurrentNDOutput() {
      return this.currentNDOutput == "Yes";
    },
    getPP() {
      return this.b64img;
    },
  },
  components: {
    BaseMultiValue,
    BaseMultiValue2,
    BaseRadioOther,
    BaseCheckBoxOther,
    BaseCheckBoxSkillSet,
    BaseDropdown,
    TeamDropdown,
  },
  methods: {
    setData(event, varToChange) {
      this[varToChange] = event;
      // console.log(event)
      //COMPILE WHEN SUBMIT , KEEP CHECKBOX SEPERATE
    },
    setDataCheckbox(event, varToChange) {
      this[varToChange[0]] = event["answer"];
      if (event["otherBool"] == true) {
        this[varToChange[1]] = event["other"];
      } else {
        this[varToChange[1]] = "";
      }
    },
    buttonHandlingAfterSubmit() {
      this.showProcessButton = false;
      this.showLoading = true;
      this.isBtnDisable = true;
    },
    async redirectHome() {
      //refresh data after submission
      await this.$store.dispatch("getAllData", [
        this.$store.state.token,
        this.$store.state.username,
      ]);
      // need to show loading after this, cos if internet slow
      this.$router.push({
        name: "home",
      });
    },

    async onDelete() {
      this.isBtnDisable = true;
      this.showDeleteButton = false;
      let bodyToDel = {
        id: this.id,
        pk: "0",
        date: this.formatDateNow, //this is edited date  always will be overwritten
      };

      await this.$store.dispatch("getAuth");
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`); // Must pass token for every call
      myHeaders.append("username", this.$store.state.username); // send data to backend
      // console.log(JSON.stringify(body))
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(bodyToDel),
      };

      let res = await fetch(`${APIURL}/delete-person`, requestOptions);

      alert("This entry will be deleted.");

      this.isBtnDisable = false;
      this.showLoading = true;
      this.showDeleteButton = true;
      this.redirectHome();
    },

    async onSubmit() {
      this.showProcessButton = true;
      this.isBtnDisable = true;
      const skillItems = [];
      if (this.skillOutput.length > 0) {
        this.skillOutput.forEach((el, id) => {
          skillItems.push({ id: id, value: el });
        });
      }
      if (this.skillOutputOther.length > 0) {
        this.skillOutputOther.forEach((el, id) => {
          skillItems.push(el);
        });
      }
      // form validation , error handling
      this.error = [];
      if (!this.candidateName) {
        this.error.push("Candidate Name");
      }
      if (!this.honorificOutput) {
        this.error.push("Honorific");
      }
      // if (!this.birthYear || isNaN(this.birthYear)) {
      //   //need to check for number only
      //   this.error.push("Year Of Birth");
      // }
      // will allow NA
      if (!this.genderOutput) {
        this.error.push("Gender");
      }
      if (!this.nationalityOutput) {
        this.error.push("Nationality");
      }
      if (!this.ethnicityOutput && this.branchNationalityOutput) {
        this.error.push("Ethnicity");
      } //when edit mode certain data will be null
      if (!this.countryOutput.length) {
        this.error.push("Country Of Residence");
      }
      // Optional
      // if (!this.comments.length) {
      //     this.error.push("Team's Comment")
      // }
      if (!this.currentNDOutput.length) {
        this.error.push("Current Khazanah ND?");
      }

      if (!this.kAlumniOutput.length) {
        this.error.push("Is Khazanah Alumni?");
      }

      //branch
      //multi value
      if (this.branchCurrentNDOutput) {
        if (!this.ndCompanyOutput) {
          this.error.push("Name of Investee Co or SPV");
        } else {
          this.ndCompanyOutput.forEach((el) => {
            if (!el["value"]) {
              this.error.push("Name of Investee Co or SPV");
            }
          });
        }
      }
      //branch
      // multi value
      if (this.branchCurrentNDOutput) {
        if (!this.assetClassOutput) {
          this.error.push("Asset Class");
        } else {
          this.assetClassOutput.forEach((el) => {
            if (!el["value"]) {
              this.error.push("Asset Class");
            }
          });
        }
      }

      //multivalue
      if (!this.previousEmploymentOutput.length) {
        this.error.push("Previous Employment");
      } else {
        this.previousEmploymentOutput.forEach((el) => {
          if (!el["value"]) {
            this.error.push("Previous Employment");
          }
        });
      }

      //multivalue
      if (!this.previousIndustriesOutput) {
        this.error.push("Previous Industries");
      } else {
        this.previousIndustriesOutput.forEach((el) => {
          if (!el["value"]) {
            this.error.push("Previous Industries");
          }
        });
      }

      //multivalue need to try?
      if (!skillItems) {
        this.error.push("Candidate Skill Set");
      } else {
        skillItems.forEach((el) => {
          if (!el["value"]) {
            this.error.push("Candidate Skill Set");
          }
        });
      }
      this.error = [...new Set(this.error)];

      if (this.error.length > 0) {
        //
        let errorString = "Form Invalid.Please recheck the fields below:";
        this.error.forEach((el) => {
          errorString = errorString.concat(`\n${el}`);
        });
        alert(errorString);
        this.showProcessButton = false;
        this.isBtnDisable = false;
      } else {
        ////

        if (this.formMode != "Edit") {
          //if form mode is create new
          this.id = uuidv4();
          this.reviewed = false;
          this.archived = false;
          this.isRecommended = false;
          //need to edit later
        } else {
          if (this.archived == "") {
            this.archived = false;
          }

       


        }

        if (this.birthYear == "") {
          this.birthYear = null;
        }
        const body = {
          id: this.id,
          name: this.candidateName, //string
          honorific: this.honorificOutput, //string
          birth_year: this.birthYear, //number or null
          gender: this.genderOutput, //string
          nationality: this.nationalityOutput, //string
          ethnicity: this.ethnicityOutput, //string
          residence_country: this.countryOutput, //string
          comments: this.comments, //string
          url: this.url, //string
          k_alumni: this.kAlumniOutput,
          // below all array of objects that contain strings as value
          k_nd: [
            {
              id: 0,
              value: this.currentNDOutput,
            },
          ],
          nd_company: this.ndCompanyOutput,
          asset_class: this.assetClassOutput,
          education: this.educationOutput,
          qualification: this.qualificationOutput,
          membership: this.membershipOutput,
          current_position: this.currentPositionOutput,
          previous_employment: this.previousEmploymentOutput,
          previous_industries: this.previousIndustriesOutput,
          skill: skillItems,
          reviewed: this.reviewed, //string
          is_recommended: this.isRecommended,
          b64img: this.b64img, //string
          pk: "0",
          date: this.formatDateNow, //this is edited date  always will be overwritten
          archived: this.archived, //
          email_author: this.emailAuthor, //only change during created new submission
          iv_team: this.ivTeam,
          email_editor: this.$store.state.username, //always will be overwritten
          created_date: this.createdDate, ////only change during created new submission
          has_attachment: this.hasAttachment,
        };

        //branching, if no then remove
        if (!this.branchCurrentNDOutput) {
          delete body["nd_company"];
          delete body["asset_class"];
        }
        if (!this.branchNationalityOutput) {
          delete body["ethnicity"];
        }

        await this.$store.dispatch("getAuth");
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`); // Must pass token for every call
        myHeaders.append("username", this.$store.state.username); // send data to backend
        // console.log(JSON.stringify(body))
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(body),
        };

        if (this.formMode == "Edit") {
          const res = await fetch(`${APIURL}/update-person`, requestOptions);
          this.handleAttachment();
          alert("Thank you for the submission.");
        } else {
          const res = await fetch(`${APIURL}/create-person`, requestOptions);
          this.handleAttachment();
          //thank you message and redirect to home page
          alert(
            "Thank you for the submission.It will be reviewed by IMO before appearing at the home page."
          );
        }
        // need to refactor this better
        this.showProcessButton = false;
        this.showLoading = true;
        this.isBtnDisable = true;
        //refresh data after submission
        this.redirectHome();
      }
    },
    encodeImageFileAsURL(element) {
      var file = this.$refs.file.files[0];
      // console.log("under encode Image File A URL", file)
      const fileSize = file.size / 1024 / 1024; //in MiB
      // console.log("file size", fileSize)
      if (fileSize > 0.5) {
        alert("File Size exceeds 500kb. Please reupload smaller file");
        this.$refs.file.value = null;
      } else {
        var reader = new FileReader();
        reader.onload = (event) => {
          this.b64img = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    encodeFileAsBlob(element) {
      var file = this.$refs.pdffile.files[0];
      const fileSize = file.size / 1024 / 1024; // in MiB
      console.log("file name", file.name);

      if (fileSize > 5) {
        alert("File size exceeds 5Mb. Please upload a smaller file.");
        this.$refs.pdffile.value = null;
      } else if (file.type !== "application/pdf") {
        alert("Only PDF files are allowed. Please upload a PDF file.");
        this.$refs.pdffile.value = null;
      } else {
        var reader = new FileReader();
        reader.onload = (event) => {
          var arrayBuffer = event.target.result;
          var blob = new Blob([arrayBuffer], { type: "application/pdf" });
          this.pdfFile = blob;
          this.pdfFileName = file.name;
        };
        reader.readAsArrayBuffer(file); //if edit form need to read if exist
        this.hasAttachment = true;
      }
    },

    async handleAttachment(element) {
      //need to pass id as well
      console.log("Blob:", this.pdfFileName.length);
      //   await this.$store.dispatch("getAuth");
      if (this.pdfFileName.length > 0) {
        6;
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`); // Must pass token for every call
        myHeaders.append("username", this.$store.state.username); // send data to backend
        var formData = new FormData();
        //check if empty then no need upload
        formData.append("file", this.pdfFile); //is this empty if not uploaded??

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formData,
        };

        const res = await fetch(
          `${APIURL}/upload-blob?id=${this.id}`,
          requestOptions
        );

        if (!res.ok) {
          console.log("Error Uploading PDF");
        }
      }
    },
    async getAttachment() {
      await this.$store.dispatch("getAuth");
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`); // Must pass token for every call
      myHeaders.append("username", this.$store.state.username); // send data to backend

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      const res = await fetch(
        `${APIURL}/get-blob-by-name/${this.id}.pdf`,
        requestOptions
      );

      if (res.ok) {
        const blob = await res.blob();
        var downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = this.candidateName + "_attachment.pdf";
        downloadLink.innerText = this.candidateName + "_attachment.pdf";

        this.hasAttachment = true;
      } else {
        console.log("Error");
        var downloadLink = document.createElement("a");
        downloadLink.innerText = "No attachment found";
        downloadLink.className = "italic";
        this.hasAttachment = false;
      }
      const downloadLinkContainer = document.getElementById(
        "downloadLinkContainer"
      );
      downloadLinkContainer.innerHTML = ""; // Clear any existing content
      downloadLinkContainer.appendChild(downloadLink);
    },
  },
  created() {
    this.showLoading = false;
    if (this.formMode == "Edit") {
      this.$store.dispatch("trackEditSubmission");

      // console.log(this.$route.query.uuid)
      //making copy not reference
      const dataEdit = this.$store.state.ndList.filter((el) => {
        return el.id == this.$route.query.uuid;
      })[0];
      this.id = dataEdit["id"] || "";
      this.candidateName = dataEdit["name"] || "";
      this.birthYear = dataEdit["birth_year"] || "";
      this.comments = dataEdit["comments"] || "";
      this.url = dataEdit["url"] || "";
      //assign to radio box
      //assign to checkboxes
      this.honorificOutput = dataEdit["honorific"] || "";
      this.genderOutput = dataEdit["gender"] || "";
      this.nationalityOutput = dataEdit["nationality"] || "";
      this.ethnicityOutput = dataEdit["ethnicity"] || "";
      this.countryOutput = dataEdit["residence_country"] || "";
      this.kAlumniOutput = dataEdit["k_alumni"] || "";
      this.currentNDOutput = dataEdit["k_nd"][0]["value"] || "";
      //multiValue
      this.ndCompanyOutput = cloneDeep(dataEdit["nd_company"]) || [
        { id: 0, value: "" },
      ];
      this.assetClassOutput = cloneDeep(dataEdit["asset_class"]) || [
        { id: 0, value: "" },
      ];
      this.educationOutput = cloneDeep(dataEdit["education"]) || [
        { id: 0, value: "" },
      ];
      this.qualificationOutput = cloneDeep(dataEdit["qualification"]) || [
        { id: 0, value: "" },
      ];
      // console.log(this.qualificationOutput)
      this.membershipOutput = cloneDeep(dataEdit["membership"]) || [
        { id: 0, value: "" },
      ];
      this.currentPositionOutput = cloneDeep(dataEdit["current_position"]) || [
        { id: 0, value: "" },
      ];
      this.previousEmploymentOutput = cloneDeep(
        dataEdit["previous_employment"]
      ) || [{ id: 0, value: "" }]; //need to change here
      this.previousIndustriesOutput = cloneDeep(
        dataEdit["previous_industries"]
      ) || [{ id: 0, value: "" }];
      //need to do here if Reviewed then check, if Pending not checked
      if (dataEdit["reviewed"] == "Reviewed") {
        this.reviewed = true;
      } else {
        this.reviewed = false;
      }

        //handle is_recommended
        if (dataEdit["is_recommended"] =="Executive/ Senior Management"){
          this.isRecommended = true

        }else if (dataEdit["is_recommended"]=="Director"){

          this.isRecommended = false

        }else{
          this.isRecommended = false
        }


      this.b64img = dataEdit["b64img"] || "";
      //if Archived then check , if Live not checked
      if (dataEdit["archived"] == "Archived") {
        this.archived = true;
      } else {
        this.archived = false;
      }
      this.emailAuthor = dataEdit["email_author"] || "";
      this.emailEditor = dataEdit["email_editor"] || "";
      this.createdDate = dataEdit["created_date"] || "";
      this.ivTeam = dataEdit["iv_team"] || "";

      // checkbox
      this.skillOutputEdit = dataEdit["skill"];
      dataEdit["skill"].forEach((el) => {
        if (this.skillSetChoice.includes(el["value"])) {
          this.skillOutput.push(el["value"]);
        } else {
          this.skillOutputOther.push(el);
        }
      });

      this.getAttachment();
      // this.pdfFileName = this.candidateName +"_attachment.pdf"
    } else {
      console.log("Form Mode is Create");
      this.createdDate = this.formatDateNow;
      this.emailAuthor = this.$store.state.username;

      this.$store.dispatch("trackNewSubmission");
    }
  },
};
</script>
