<template>
	<img :src="ppUrl" class="h-12 rounded-full mr-2 ml-auto" alt="" />
</template>

<script>
import * as msal from "@azure/msal-browser";
import { myMSALObj } from "../authConfig";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { Client } from "@microsoft/microsoft-graph-client";
export default {
	name: "TheHeaderPP",
	props: {},
	data() {
		return {
			ppUrl: "",
		};
	},
	components: {},
	async created() {
		await this.$store.dispatch("getAuth");

		const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(myMSALObj, {
			account: myMSALObj.getAccountByUsername(this.$store.state.username), // the AccountInfo instance to acquire the token for
			scopes: ["user.read"],
			interactionType: msal.InteractionType.Silent,
		});

		// Initialize the Graph client
		const graphClient = Client.initWithMiddleware({ authProvider });
		try {
			let res = await graphClient.api("/me/photos/240x240/$value").get();
			//   const data = await res.blob();
			const url = window.URL || window.webkitURL;
			this.ppUrl = url.createObjectURL(res);
		} catch (error) {
			throw error;
		}
	},
};
</script>
