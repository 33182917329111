<template>

  <div>
    <!-- //need v model somewheree -->

    <div v-for="item in getObjectList" :key="item['id']" class="flex flex-row gap-x-1 -ml-1">
      <div class="flex flex-row gap-x-1">
        <BaseDropdown class="w-32" :id="item['id']" :answer="item['start']" :choiceList="yearChoice"
          placeholder="Start Year" @answerChange="setStartByID" />
        <BaseDropdown class="w-32" :id="item['id']" :answer="item['end']" :choiceList="yearChoice"
          placeholder="End Year" @answerChange="setEndByID" v-if="isUntilPresent" />
        <BaseDropdown class="w-32" :id="item['id']" :answer="item['title']" :choiceList="titleChoice"
          placeholder="Title" @answerChange="setTitleByID" />
      </div>

      <BaseMultiValueTextInput :id="item['id']" :item="item" @deleteItem="deleteItem" @oneValueChange="setValueByID"
        class="w-full mx-1" :showDel=showDel />


    </div>
    <button type="button" class="flex space-x-2 justify-start mt-3" @click="addItem">
      <div class="rounded-lg text-white bg-firstBlue px-3 mx-1">
        + Add More
      </div>
      <!-- <p>Add More</p> -->
    </button>
  </div>


</template>
    
<script>
import BaseMultiValueTextInput from '../components/BaseMultiValueTextInput.vue'
import BaseDropdown from './BaseDropdown.vue'
import { sortBy, range,maxBy,cloneDeep } from 'lodash-es'
export default {


  name: 'BaseMultiValue2',
  data() {
    return {
      //need to split objectList to single field with id
      objectList: [{ "id": 0, "start": "", "end": "", "title": "", "value": "" }],
      titleChoice: ["NINE", "INE", "CEO", "CIO", "CTO", "CFO", "CHRO", "Advisor", "-"],//hardcoded here instead of parent
      yearChoice: [],
      counter: 0
    }
  },
  props: {
    fieldName: String,
    editData: Array,
    formMode: String,
    choiceList: Array,
    isUntilPresent: Boolean
  },
  computed: {
    getObjectList() {
      return this.objectList
    },
    showDel() {
      if (this.objectList.length > 1) {
        return true
      } else { return false }
    }

  },
  components: { BaseMultiValueTextInput, BaseDropdown },
  methods: {
    addItem() {
      this.counter++
      this.objectList.push({ "id": this.counter, "start": "", "end": "", "title": "", "value": "" })//when click add item, add empty value first


    },
    deleteItem(itemID) {
      this.objectList = this.objectList.filter(el => {

        return (el["id"] != itemID)
      })
    },
    setValueByID(item) {
      //need to change this method to cater for other fields
      const index = this.objectList.findIndex(el => {
        return el["id"] == item["id"]
      })
      if (index !== -1) {
        //
        this.objectList[index]["value"] = item["value"]
      }

    },
    setStartByID(item) {
      //need to check why it doesnt save
      console.log("setSTartByID", item["answer"])
      const index = this.objectList.findIndex(el => {
        return el["id"] == item["id"]
      })
      if (index !== -1) {
        //
        this.objectList[index]["start"] = item["answer"]
      }

    },
    setEndByID(item) {
      //need to change this method to cater for other fields
      const index = this.objectList.findIndex(el => {
        return el["id"] == item["id"]
      })
      if (index !== -1) {
        //
        this.objectList[index]["end"] = item["answer"]
      }

    },
    setTitleByID(item) {
      //need to change this method to cater for other fields
      const index = this.objectList.findIndex(el => {
        return el["id"] == item["id"]
      })
      if (index !== -1) {
        //
        this.objectList[index]["title"] = item["answer"]
      }

    },

  },
  watch: {
    // 
    objectList: {
      handler(newValue, oldValue) {
        // console.log(newValue)
        this.$emit("multiValueChange", newValue)
      }, deep: true


    }
  },
  created() {
    this.yearChoice = range(new Date().getFullYear(), 1980, -1)
    if (this.formMode == "Edit") {
      this.objectList = [...this.editData]
      var maxID = cloneDeep(maxBy(this.editData,"id"))
      // console.log("created in edit form",maxID.id)
      try{
        this.counter = maxID.id //will fail if it is empty
      }
        catch{this.counter = 0} 
      
    }

  }
}



</script>