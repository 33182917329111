<template>

  <div>
    <!-- //need v model somewheree -->
    
    <div v-for="item in getObjectList" :key="item['id']">
      <BaseMultiValueTextInput :id="item['id']" :item="item" @deleteItem="deleteItem" @oneValueChange="setDataByID" :showDel=showDel />
    </div>
    <button type="button" class="flex space-x-2 justify-start mt-3" @click="addItem">
      <div class="rounded-lg text-white bg-firstBlue px-3 mx-1">
        + Add More
      </div>
      <!-- <p>Add More</p> -->
    </button>
  </div>


</template>
    
<script>
import BaseMultiValueTextInput from '../components/BaseMultiValueTextInput.vue'
export default {


  name: 'BaseMultiValue',
  data() {
    return {
      objectList: [{ "id": 0, "value": "" }],
      counter:0
    }
  },
  props:{
    fieldName:String,
    editData:Array,
    formMode:String
  },
  computed:{
    getObjectList(){
      return this.objectList
    },
    showDel(){
      if (this.objectList.length>1){
        return true
      }else{ return false}
    }

  },
  components: { BaseMultiValueTextInput },
  methods:{
    addItem(){
      this.counter++
      this.objectList.push({ "id": this.counter, "value": "" })
      

    },
    deleteItem(itemID){
      this.objectList = this.objectList.filter(el=>{

        return (el["id"]!=itemID)
      })
    },
    setDataByID(item){

      const index = this.objectList.findIndex(el=>{
        return el["id"] == item["id"]
      })
      if (index!== -1){
        this.objectList[index]["value"] = item["value"]
      }
      
    }
    
  },
  watch:{
    // 
    objectList:{
      handler(newValue,oldValue){
        // console.log(newValue)
        this.$emit("multiValueChange",newValue)
      },deep:true
      

    }
  },
  created(){
    if(this.formMode=="Edit"){
      this.objectList = [...this.editData]
    }
  }
}



</script>