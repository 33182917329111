<template>
  <div class="pt-3">
    <span class="font-semibold"></span>
    <div class="flex flex-col gap-x-5 gap-y-5 text-black md:flex-row md:flex-wrap">
      <div class="">
        Gender
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'gender'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      <div>
        Ethnicity
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'ethnicity'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      <div>
        Nationality
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'nationality'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div>
        Khazanah Alumni
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'k_alumni'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      
      <div class="k-nd" >
        Khazanah ND
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'k_nd'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      
      <div class="">
        Age
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'ageRange'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>
      <div class="">
        Sort by
        <div v-for="choice in sortChoice">
          <label class="inline-flex items-center">
            <input
              type="radio"
              class="form-radio"
              name="sortRadio"
              :value="choice.label"
              @input="updateSort($event, choice.id)"
            />
            <span class="ml-2">{{ choice.label }}</span>
          </label>
        </div>
      </div>
      <div class="recommended" >
        Recommended Positions
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'recommended'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>


      <div class="" v-show="this.$store.state.adminRole">
        Reviewed [Admin View]
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'reviewed'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="" v-show="this.$store.state.adminRole">
        Archived [Admin View]
        <div v-for="filter in filterArray">
          <label
            class="inline-flex items-center"
            v-if="filter.type == 'archived'"
          >
            <input
              type="checkbox"
              class="form-checkbox"
              :value="filter.label"
              @input="updateCheckBox($event, filter.id)"
            />
            <span class="ml-2">{{ filter.label }}</span>
          </label>
        </div>
      </div>

      <div class="" v-show="this.$store.state.adminRole">
        Submitted By [Admin View]
        <div class="flex flex-col"> 
        <div class="flex flex-row items-center">
          <span>Email:</span>
          <select
            name="dropdown"
            v-model="selectedAuthor"
            class="block w-full mt-1 mx-1 border-none rounded-lg"
            @change="updateDropDownAuthor($event, selectedAuthor)"
          >
            <option value="" default selected class=""></option>
            <option v-for="filter in authorList" :value="filter">
              {{ filter.label }}
            </option>
          </select>
        </div>
        <div class="flex flex-row items-center">
          <span>Team:</span>
          <select
            name="dropdown"
            v-model="selectedIvTeam"
            class="block w-full mt-1 mx-1 border-none rounded-lg"
            @change="updateDropDownTeam($event, selectedIvTeam)"
          >
            <option value="" default selected class=""></option>
            <option v-for="filter in ivTeamList" :value="filter">
              {{ filter.label }}
            </option>
          </select>
        </div>

      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseFilterCategory",
  props: {},
  data() {
    return {
      selectedAuthor: "",
      selectedIvTeam :""
    };
  },
  computed: {
    filterArray() {
      return this.$store.state.filterArray;
    },
    authorList() {
      return this.filterArray.filter((el) => {
        return el.type == "author";
      });
    },
    ivTeamList() {
      return this.filterArray.filter((el) => {
        return el.type == "iv_team";
      });
    },
    sortChoice() {
      if (this.$route.name == "SearchView") {
        return this.$store.state.sortMethod;
      } else {
        return this.$store.state.sortMethod.filter((x) => {
          return x.id != "@search.score";
        });
      }
    },
  },
  methods: {
    updateCheckBox(event, id) {
      this.$store.commit(
        "setFilterItem",
        this.$store.state.filterArray.findIndex((obj) => obj.id == id)
      );
    },
    updateDropDownAuthor(event, item) {
      //clear all filter
      this.authorList.forEach((el) => {
        this.$store.commit(
          "clearFilterItem",
          this.$store.state.filterArray.findIndex((obj) => obj.id == el.id)
        );
      });
      //then select new one
      console.log(item.id);
      this.$store.commit(
        "setFilterItem",
        this.$store.state.filterArray.findIndex((obj) => obj.id == item.id)
      );
    },
    updateDropDownTeam(event, item) {
      //clear all filter
      this.ivTeamList.forEach((el) => {
        this.$store.commit(
          "clearFilterItem",
          this.$store.state.filterArray.findIndex((obj) => obj.id == el.id)
        );
      });
      //then select new one
      console.log(item.id);
      this.$store.commit(
        "setFilterItem",
        this.$store.state.filterArray.findIndex((obj) => obj.id == item.id)
      );
    },
    updateSort(event, id) {
      this.$store.commit(
        "setSortMethod",
        this.$store.state.sortMethod.findIndex((obj) => obj.id == id)
      );
    },
  },
  mounted() {
    this.$store.dispatch("resetFilter");

    if (this.$route.name == "SearchView") {
      //need to set default is relevancy
      this.updateSort(null, "@search.score");
    } else {
      this.updateSort(null, "name");
    }
  },
};
</script>
