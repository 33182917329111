<template>
  <div
    class="bg-white rounded-lg my-3 px-2"
    v-if="
      this.$store.state.adminRole ||
      (metaDisplay['reviewed'] == 'Reviewed' &&
        metaDisplay['archived'] == 'Live')
    "
  >
    <div id="section 1" class="flex justify-between w-full">
      <div id="pic, name, education" class="flex w-full">
        <img
          :src="metaDisplay.b64img || defaultimg"
          class="h-14 w-14 rounded-full mt-2"
          alt=""
        />
        <div id="name and education" class="mt-1 ml-2 w-full">
          <div
            id="name-tags-expands"
            class="flex flex-row items-start justify-between"
          >
            <div id="nameonly">
              <a
                v-if="metaDisplay.url"
                :href="metaDisplay.url"
                target="_blank"
                class="text-lg text-slate-600 hover:underline font-semibold hover:text-secondBlue"
              >
                {{ metaDisplay.name }}
              </a>
              <span
                v-if="!metaDisplay.url"
                class="text-lg text-slate-600 font-semibold"
              >
                {{ metaDisplay.name }}
              </span>
            </div>
            <div id="tags and expand" class="px-1 flex justify-end space-x-1">
              <span
                id="reviewed"
                class="w-24 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-thirdBlue rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
                v-if="
                  this.$store.state.adminRole &&
                  metaDisplay.reviewed == 'Reviewed'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>

                : Reviewed
              </span>

              <span
                id="not-reviewed"
                class="w-24 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-secondOrange rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
                v-if="
                  this.$store.state.adminRole &&
                  metaDisplay.reviewed == 'Pending'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>

                : Pending
              </span>
              <span
                id="archived"
                class="w-24 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-secondOrange rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
                v-if="
                  this.$store.state.adminRole &&
                  metaDisplay.archived == 'Archived'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                : Archived
              </span>
              <span
                id="not-archived"
                class="w-16 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-thirdBlue rounded-xl border-gray-400 text-black text-xs font-semibold py-1 flex"
                v-if="
                  this.$store.state.adminRole && metaDisplay.archived == 'Live'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                : Live
              </span>

              <button
                id="admin edit"
                class="leading-none pt-1 border-skin-input bg-white text-secondBlue bg-clip-content items-center justify-center font-semibold text-xs hover:scale-125"
                v-if="this.$store.state.adminRole"
                @click="editForm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.0"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </button>

              <!-- new button here if they have attachment -->
              <button
                v-if="metaDisplay.has_attachment"
                @click="handleDownload"
                class="leading-none pt-1 border-skin-input bg-white text-secondBlue bg-clip-content items-center justify-center font-semibold text-xs hover:scale-125"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </button>

              <button
                class="leading-none pt-1 border-skin-input bg-white text-secondBlue bg-clip-content items-center justify-center font-semibold text-xs hover:scale-125"
                @click="setActiveCard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.0"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div
            v-for="item in metaDisplay.education"
            class="flex flex-col text-sm"
          >
            <p class="text-sm">{{ item.value }}</p>
          </div>

          <span
            class="w-16 h-8 px-auto justify-center md:grid-cols-2 flex-wrap leading-none bg-clip-content items-center bg-firstGray rounded-xl border-gray-400 text-white text-xs font-semibold py-1 flex"
          >
            Age: {{ metaDisplay.age }}
          </span>
        </div>
      </div>
    </div>
    <div id="section 2 current details">
      <span>
        <div class="flex flex-wrap items-center justify-start ml-1 mt-2 mb-2">
          <p class="font-semibold">Current ND:</p>
          <div v-for="item in metaDisplay.asset_class" class="flex flex-row">
            <p
              class="bg-white rounded-xl text-firstBlue font-semibold border-2 border-firstBlue px-3 ml-2 text-sm"
            >
              {{ item.value }}
            </p>
          </div>
        </div>
        <div v-for="item in metaDisplay.nd_company" class="ml-2 text-sm">
          <p class="">
            {{ item.value }}
          </p>
        </div>
        <!-- #probably need to add splitter -->
        <div class="ml-1 mt-2 mb-1">
          <p class="font-semibold">Current position(s):</p>
          <!-- need to limit to 3 and add ... -->
          <div
            id="position"
            v-for="item in metaDisplay.current_position"
            class="flex flex-col text-sm"
          >
            <p :class="['px-1', isExpand ? '' : 'line-clamp-1']">
              <span v-if="item.start">{{ item.start + ": " }}</span>{{item.value }}
            </p>
          </div>
          <button
            id="button"
            @click="expandCard"
            class="px-1 text-firstBlue text-sm font-semibold hover:font-semibold hover:text-secondBlue border-firstGray"
            v-if="displayCurrentPositions"
          >
            <!--Only show when length >3 -->
            See {{ moreOrLess }}
          </button>
        </div>
      </span>
      <div id="skills" class="pb-2 mt-2 flex flex-row flex-wrap">
        <div v-for="item in metaDisplay.skill" class="my-1">
          <p
            class="bg-kGreen rounded-xl text-white font-semibold py-1 px-4 mx-1 text-sm"
          >
            {{ item.value }}
          </p>
        </div>
      </div>

      <div
        id="recommendation"
        v-show="metaDisplay.is_recommended=='Executive/ Senior Management'"
        class="pb-2 mt-2 flex flex-row flex-wrap"
      >
        <p
          class="bg-thirdBlue rounded-xl text-black font-semibold py-1 px-4 mx-1 text-sm"
        >
          Exec/SM
        </p>
      </div>

      <div
        v-if="this.$store.state.adminRole"
        class="flex flex-col ml-1 mt-2 w-full items-start"
      >
        <div class="text-sm italic flex flex-row gap-x-0">
          <span class="">
            <span class="font-semibold">Submitted by:</span>
            {{ metaDisplay.email_author }}
            <span v-if="metaDisplay.iv_team">, {{ metaDisplay.iv_team }} </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultimgjson from "../assets/defaultimg.json";
export default {
  name: "BaseDataCard",
  props: {
    meta: Object, //this is Object not Array!!!!
    isAdmin: Boolean,
  },
  data() {
    return {
      maxLine: 3,
      isExpand: false,
      needExpand: false,
      defaultimg: defaultimgjson["imgb64"],
    };
  },
  computed: {
    metaDisplay() {
      let tempObj = {};
      Object.entries(this.meta).forEach(([key, val]) => {
        //if val is array then slice it to maxLine
        if (Array.isArray(val)) {
          tempObj[key] = val.slice(0, this.maxLine);
        } else {
          tempObj[key] = val;
        }
      });

      return tempObj;
    },
    moreOrLess() {
      return this.isExpand ? "Less" : "More";
    },
    displayCurrentPositions() {
      if (this.meta.current_position != undefined) {
        return this.meta.current_position.length > 3;
      } else {
        return false;
      }
    },
    displayPreviousEmployments() {
      if (this.meta.previous_employment != undefined) {
        return this.meta.previous_employment.length > 3;
      } else {
        return false;
      }
    },
  },
  methods: {
    editForm() {
      this.$router.push({
        name: "EditView",
        query: {
          uuid: this.meta.id,
        },
      });
    },
    expandCard() {
      if (this.isExpand) {
        this.isExpand = false;
        this.maxLine = 3;
      } else {
        this.isExpand = true;
        this.maxLine = 20;
      }
    },
    setActiveCard() {
      this.$store.commit("setActiveID", this.meta.id);
    },
    handleDownload() {
      let id = this.metaDisplay.id;
      let fileName = this.metaDisplay.name;
      this.$store.dispatch("downloadAttachment", [id, fileName]);
    },
  },
  mounted() {
    Object.entries(this.meta).forEach(([key, val]) => {
      if (Array.isArray(val)) {
        if (val.length > 3) {
          this.needExpand = true;
        }
      }
    });
  },
  watch: {},
};
</script>
