<template>
	<div>
		<div v-if="!isShowTable">
			<div id="role-change" class="my-1" v-if="this.$store.state.view == 'View.Admin'">
				<label class="inline-flex items-center">
					<input
						type="checkbox"
						class="form-checkbox"
						v-model="adminRole"
						@change="setAdminRole"
					/>
					<span class="ml-2">Activate Admin Role</span>
				</label>
			</div>
			<div class="flex">
				<div class="w-1/2 pr-3">
					<div v-if="true" v-for="meta in filterList" :key="meta.id">
						<BaseDataCard :meta="meta" :isAdmin="isAdmin" />
					</div>
				</div>
				<div
					class="w-1/2 h-[calc(100vh-5.75rem)] sticky top-16 overflow-y-scroll overscroll-contain"
				>
					<div class="h-[1000px]">
						
						<ActiveDataCard :filterList="filterList" />
					</div>
				</div>
			</div>
		</div>
		<BaseDataTable :meta="filterList" v-show="isShowTable" />
	</div>
</template>

<script>
import BaseDataCard from "./BaseDataCard.vue";
import BaseDataTable from "./BaseDataTable.vue";
import ActiveDataCard from "./ActiveDataCard.vue";
import { sortBy, orderBy } from "lodash-es";
export default {
	name: "TheData",
	props: {
		isAdmin: Boolean,
		isShowTable: Boolean,
		view: String,
	},
	data() {
		return {
			adminRole: this.$store.state.adminRole,
		};
	},
	computed: {
		filterList() {
			if (this.view == "search") {
				var x = this.$store.state.searchResult;// the search result should return id and we filter ndList based on that
				// so need to get ndList if doesnt exist yet.
				
			} else {
				var x = this.$store.state.ndList;
			}
			try {
				x = x.filter((el) => {
					var filterGender = this.$store.state.filterArray.filter(
						(el2) => el2.type == "gender" && el2.checked == true
					);
					var filterEthnicity = this.$store.state.filterArray.filter(
						(el2) => el2.type == "ethnicity" && el2.checked == true
					);
					var filterNationality = this.$store.state.filterArray.filter(
						(el2) => el2.type == "nationality" && el2.checked == true
					);
					var filterKAlumni = this.$store.state.filterArray.filter(
						(el2) => el2.type == "k_alumni" && el2.checked == true
					);
					var filterAgeRange = this.$store.state.filterArray.filter(
						(el2) => el2.type == "ageRange" && el2.checked == true
					);
					var filterReviewed = this.$store.state.filterArray.filter(
						(el2) => el2.type == "reviewed" && el2.checked == true
					);
					var filterRecommended = this.$store.state.filterArray.filter(
						(el2) => el2.type == "recommended" && el2.checked == true
					);

					var filterKND = this.$store.state.filterArray.filter(
						(el2) => el2.type == "k_nd" && el2.checked == true
					);


					var filterArchived = this.$store.state.filterArray.filter(
						(el2) => el2.type == "archived" && el2.checked == true
					);
					var filterAuthor = this.$store.state.filterArray.filter(
						(el2) => el2.type == "author" && el2.checked == true
					);
					var filterIvTeam = this.$store.state.filterArray.filter(
						(el2) => el2.type == "iv_team" && el2.checked == true
					);

					//need case where all false
					if (!filterGender.length) {
						filterGender = true;
					} else {
						filterGender = filterGender.map((el3) => el3.label).includes(el.gender);
					}
					if (!filterEthnicity.length) {
						filterEthnicity = true;
					} else {
						filterEthnicity = filterEthnicity
							.map((el3) => el3.label)
							.includes(el.ethnicity);
					}
					if (!filterNationality.length) {
						filterNationality = true;
					} else {
						filterNationality = filterNationality
							.map((el3) => el3.label)
							.includes(el.nationality);
					}

					if (!filterKAlumni.length) {
						filterKAlumni = true;
					} else {
						filterKAlumni = filterKAlumni
							.map((el3) => el3.label)
							.includes(el.k_alumni);
					}
					if (!filterAgeRange.length) {
						filterAgeRange = true;
					} else {
						filterAgeRange = filterAgeRange.map((el3) => el3.label).includes(el.ageRange);
					}

					if (!filterReviewed.length) {
						filterReviewed = true;
					} else {
						filterReviewed = filterReviewed.map((el3) => el3.label).includes(el.reviewed);
					}

					if (!filterRecommended.length) {
						filterRecommended = true;
					} else {
						filterRecommended = filterRecommended.map((el3) => String(el3.label)).includes(String(el.is_recommended));
						// console.log("trigger else",filterRecommended)
					}

					if (!filterKND.length) {
						filterKND = true;
					} else {
						filterKND = filterKND.map((el3) => String(el3.label)).includes(String(el.k_nd[0].value));


					}

					if (!filterArchived.length) {
						filterArchived = true;
					} else {
						filterArchived = filterArchived.map((el3) => el3.label).includes(el.archived);
					}

					if (!filterAuthor.length) {
						filterAuthor= true;
					} else {
						filterAuthor = filterAuthor.map((el3) => el3.label).includes(el.email_author);
					}

					if (!filterIvTeam.length) {
						filterIvTeam= true;
					} else {
						filterIvTeam = filterIvTeam.map((el3) => el3.label).includes(el.iv_team);
					}

					return (
						filterGender &&
						filterEthnicity &&
						filterNationality &&
						filterKAlumni &&
						filterAgeRange &&
						filterReviewed &&
						filterArchived &&
						filterAuthor &&
						filterIvTeam &&
						filterRecommended && 
						filterKND
					);
				});
				//console.log("in data",this.$store.state.sortMethod.filter(el=>el.checked==true)[0]["id"])
				return orderBy(
					x,
					['archived','reviewed',this.$store.state.sortMethod.filter((el) => el.checked == true)[0]["id"]],
					['desc','desc',this.$store.state.sortMethod.filter((el) => el.checked == true)[0]["order"]]
				);
			} catch (err) {
				console.log(err);
				if (this.view == "search") {
					var x = this.$store.state.searchResult;
				} else {
					var x = this.$store.state.ndList;
				}
				return x;
			}
		},
	},
	methods: {
		setAdminRole(value) {
			this.$store.commit("setAdminRole", this.adminRole);
		},
	},
	components: { BaseDataCard, BaseDataTable, ActiveDataCard },
};
</script>
