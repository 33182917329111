<template>
  <div class="bg-cover bg-gray-100 font-roboto">
    <div class="max-w-[90%] min-h-screen mx-auto">

      <div id="logo and title" class="grid grid-cols-3">
        <div class="col-start-2">
          <div class="items-center flex">
            <div id="logo" class="w-[150px] mb-3">
              <BaseLogo />
            </div>

            <div class="items-center content-center justify-center">
              <div
                class="text-4xl pb-2 text-[#70AD47] tracking-widest font-rosario text-center"
              >
                Andromeda
              </div>
              <router-link
                to="/"
                class="tracking-wider font-lilita font-semibold text-gray-500 text-md"
                >Talent Database</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <TheSearchBar />
      <button
        id="open-filter-pane"
        class="flex flex-row mobileonly:transition mobileonly:duration-300 mobileonly:ease-in-out"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#filterPane"
        aria-expanded="true"
        aria-controls="filterPane"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 fill-firstBlue"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
          />
        </svg>

        <h4 class="font-semibold text-firstBlue hover:text-secondBlue">
          Filters
        </h4>
      </button>
      <div
        id="filterPane"
        class="collapse col-span-12 pr-5 mobileonly:collapse md:col-span-3"
      >
        <BaseFilterCategory />
      </div>
      <div v-for="index in 10" :key="index">
        <BaseSkeletonLoading v-show="showLoading" />
      </div>

      <div class="flex justify-end">
        <div
          id="toggle view button"
          class="col-span-2 display inline-flex bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full"
        >
          <button
            class="inline-flex items-center transition-colors focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-l-full px-4 py-2"
            id="grid"
            @click="this.isShowTable = false"
            :class="[!isShowTable ? 'active' : '']"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="fill-current w-4 h-4 mr-2"
            >
              <rect x="3" y="3" width="7" height="7"></rect>
              <rect x="14" y="3" width="7" height="7"></rect>
              <rect x="14" y="14" width="7" height="7"></rect>
              <rect x="3" y="14" width="7" height="7"></rect>
            </svg>
            <span>Grid</span>
          </button>
          <button
            class="inline-flex items-center transition-colors focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-full px-4 py-2"
            id="list"
            @click="this.isShowTable = true"
            :class="[isShowTable ? 'active' : '']"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="fill-current w-4 h-4 mr-2"
            >
              <line x1="8" y1="6" x2="21" y2="6"></line>
              <line x1="8" y1="12" x2="21" y2="12"></line>
              <line x1="8" y1="18" x2="21" y2="18"></line>
              <line x1="3" y1="6" x2="3.01" y2="6"></line>
              <line x1="3" y1="12" x2="3.01" y2="12"></line>
              <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
            <span>Table</span>
          </button>
        </div>
      </div>
      <div
        v-if="this.isShowTable == false"
        id="note"
        class="flex justify-end text-gray-500 pt-2"
      >
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </span>
        <span class="text-sm pl-2 pt-1">Export to CSV in Table View</span>
      </div>

      <TheData :isAdmin="true" :isShowTable="isShowTable" view="home" />
    </div>
  </div>
</template>

<script>
import TheSearchBar from "../components/TheSearchBar.vue";
import BaseSkeletonLoading from "../components/BaseSkeletonLoading.vue";
import TheData from "../components/TheData.vue";
import BaseFilterCategory from "../components/BaseFilterCategory.vue";
import BaseLogo from "../components/BaseLogo.vue";


export default {
  components: {
    TheSearchBar,
    BaseSkeletonLoading,
    TheData,
    BaseFilterCategory,
    BaseLogo,
  },
  data() {
    return {
      isShowTable: false,
      pdfFile: "",
    };
  },
  methods: {
    setToggleTable() {
      this.isShowTable = !this.isShowTable;
    },
  },
  computed: {
    showLoading() {
      return this.$store.state.homeLoading;
    },
  },
};
</script>

<style>
/*@apply bg-white text-blue-400 rounded-full;*/
.active {
  background: white;
  border-radius: 9999px;
  color: #63b3ed;
}
</style>
